import { IMedia } from '../../../../../interfaces/cart/productInCart';
import { useCallback } from 'react';

export const useSrcFromMedia = () => {
    const domain = process.env.NEXT_PUBLIC_API_ENDPOINT as string;

    const imagePath = useCallback(
        (media: IMedia | null | undefined): string | undefined => {
            if (!media) return '/assets/dynamicThumbnails/thumbnailPlaceholder.png';

            return `${domain ? domain : ''}${media.path}`;
        },
        [domain]
    );

    return { imagePath };
};
