import React from 'react';
import { IDynamicThumbnail } from '../interfaces/dynamicThumbnail.interfaces';
import { IProduct } from '../../../../../interfaces/product/IProduct';
import { useSrcFromMedia } from '../hooks/useSrcFromMedia';

interface ITemplateBC2P01 {
    thumbnail: IDynamicThumbnail;
    product: IProduct;
}

export const TemplateBC2P03: React.FC<ITemplateBC2P01> = (props) => {
    const { product } = props;

    const { imagePath } = useSrcFromMedia();

    return (
        <>
            <img
                className="absolute inset-0 h-full w-full object-cover"
                src={`/assets/thumbnailBackgrounds/BC2P03.jpg`}
                alt="background"
            />

            {/* Back Business card */}
            <img
                className="absolute border border-neutral-300 shadow-lg transition-all hover:scale-110"
                style={{
                    width: '50%',
                    // height: '50%',
                    transform:
                        'perspective(600px) rotateY(3deg) rotateX(0deg) skewY(-8.5deg) skewX(4deg)',
                    transformOrigin: 'center',
                    top: '37%',
                    left: '5.9%',
                    zIndex: 1,
                }}
                src={imagePath(product.thumbnails[0])}
                alt="back-thumbnail"
            />

            {/* Front Business card */}
            <img
                className="absolute border border-neutral-300 shadow-2xl transition-all"
                style={{
                    width: '58%',
                    height: '21%',
                    transform:
                        'perspective(600px) rotateY(33deg) rotateX(50deg) skewY(-15.5deg) skewX(49deg)',
                    transformOrigin: 'center',
                    top: '59%',
                    left: '39%',
                    zIndex: 2,
                }}
                src={imagePath(product.thumbnails[1])}
                alt="front-thumbnail"
            />
        </>
    );
};
