import React, { Fragment, PropsWithChildren, useMemo } from 'react';
import { Dialog, Transition } from '@headlessui/react';

interface IModal extends PropsWithChildren {
    show: boolean;
    title?: string;
    afterClose: () => void;
    closeable?: boolean;
    maxWidthPreset?:
        | 'sm'
        | 'md'
        | 'lg'
        | 'xl'
        | '2xl'
        | '3xl'
        | '4xl'
        | '5xl'
        | '6xl'
        | '7xl'
        | 'full'
        | undefined;
}

export const ModalBorderless: React.FC<IModal> = (props) => {
    const { children, show, afterClose, maxWidthPreset = '2xl' } = props;

    const modalWidth = useMemo(() => {
        if (maxWidthPreset) {
            return `max-w-${maxWidthPreset}`;
        }
        return `max-w-5xl`;
    }, [maxWidthPreset]);

    return (
        <>
            {show && (
                <>
                    <div className="fixed top-0 left-0 z-50 flex h-screen w-screen bg-black/70 shadow-xl backdrop-blur-sm"></div>
                </>
            )}

            <Transition appear show={show} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed inset-0 z-50 h-full w-full overflow-y-auto"
                    onClose={() => afterClose()}
                >
                    <div className="relative flex h-screen max-h-screen w-screen items-center justify-center text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div
                                className={`relative flex h-max max-h-[90vH] w-min transform flex-col rounded bg-white p-0 shadow-xl transition-all ${modalWidth}`}
                            >
                                {/*<section className="relative flex w-full flex-row gap-1">*/}
                                {/*    /!*<h2 className="flex w-full grow items-center justify-center text-lg font-medium">*!/*/}
                                {/*    /!*    {title ?? ''}*!/*/}
                                {/*    /!*</h2>*!/*/}

                                {/*    {closeable && (*/}
                                {/*        <IconButton*/}
                                {/*            wrapperCss="shrink"*/}
                                {/*            onClick={() => afterClose()}*/}
                                {/*        >*/}
                                {/*            <XMarkIcon />*/}
                                {/*        </IconButton>*/}
                                {/*    )}*/}
                                {/*</section>*/}

                                <div className="relative flex max-h-screen w-full flex-col overflow-y-auto overflow-x-hidden text-left align-middle">
                                    {children}
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>
        </>
    );
};

