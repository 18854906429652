import React from 'react';
import { TemplateBC1P01 } from '../templates/TemplateBC1P01';
import { TemplateBC2P01 } from '../templates/TemplateBC2P01';
import { EDynamicThumbnailTemplate } from '../enums/dynamicThumbnails.enums';
import { IDynamicThumbnail } from '../interfaces/dynamicThumbnail.interfaces';
import { TemplateDefault } from '../templates/TemplateDefault';
import { IProduct } from '../../../../../interfaces/product/IProduct';
import { TemplateBC2P03 } from '../templates/TemplateBC2P03';
import { TemplateSingleUniversalPerspective } from '../templates/TemplateSingleUniversalPerspective';
import { TemplateSingleUniversalStraight } from '../templates/TemplateSingleUniversalStraight';
import { TemplateDoubleUniversalStraight } from '../templates/TemplateDoubleUniversalStraight';
import { TemplateDoubleUniversalStraightVertical } from '../templates/TemplateDoubleUniversalStraightVertical';

export interface IDynamicThumbnailLoader {
    thumbnail: IDynamicThumbnail;
    product: IProduct;
    showIconBar?: boolean;
}

export const DynamicThumbnailLoader: React.FC<IDynamicThumbnailLoader> = (props) => {
    const { thumbnail, product, showIconBar } = props;

    return (
        <>
            {thumbnail.template === EDynamicThumbnailTemplate.none && (
                <TemplateDefault thumbnail={thumbnail} product={product} />
            )}
            {thumbnail.template === EDynamicThumbnailTemplate.BC1P01 && (
                <TemplateBC1P01 thumbnail={thumbnail} product={product} />
            )}
            {thumbnail.template === EDynamicThumbnailTemplate.BC2P01 && (
                <TemplateBC2P01 thumbnail={thumbnail} product={product} />
            )}
            {thumbnail.template === EDynamicThumbnailTemplate.BC2P03 && (
                <TemplateBC2P03 thumbnail={thumbnail} product={product} />
            )}
            {thumbnail.template === EDynamicThumbnailTemplate.SingleUniversalPerspective && (
                <TemplateSingleUniversalPerspective thumbnail={thumbnail} product={product} />
            )}
            {thumbnail.template === EDynamicThumbnailTemplate.SingleUniversalStraight && (
                <TemplateSingleUniversalStraight thumbnail={thumbnail} product={product} />
            )}
            {thumbnail.template === EDynamicThumbnailTemplate.DoubleUniversalStraight && (
                <TemplateDoubleUniversalStraight thumbnail={thumbnail} product={product} />
            )}
            {thumbnail.template === EDynamicThumbnailTemplate.DoubleUniversalStraightVertical && (
                <TemplateDoubleUniversalStraightVertical
                    thumbnail={thumbnail}
                    product={product}
                    showIconBar={showIconBar}
                />
            )}
        </>
    );
};
