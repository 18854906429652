import React, { useMemo } from 'react';
import { IDynamicThumbnail } from '../interfaces/dynamicThumbnail.interfaces';
import { IMedia } from '../../../../../interfaces/cart/productInCart';
import { IProduct } from '../../../../../interfaces/product/IProduct';
import { DefaultThumbnail } from '../../thumbnail/DefaultThumbnail';

interface ITemplateDefault {
    thumbnail: IDynamicThumbnail;
    product: IProduct;
}

export const TemplateDefault: React.FC<ITemplateDefault> = (props) => {
    const { product } = props;

    const firstImage = useMemo((): IMedia | null => {
        if (!product || !product.thumbnails) return null;
        return product.thumbnails[0] ?? null;
    }, [product]);

    return (
        <>
            {firstImage && (
                <div className="w-full overflow-hidden aspect-[1/1]">
                    <DefaultThumbnail media={firstImage} shadowAndBorder={true} />
                </div>
            )}
        </>
    );
};
