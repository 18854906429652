import React, { useMemo } from 'react';
import { IDynamicThumbnail } from '../interfaces/dynamicThumbnail.interfaces';
import { DynamicThumbnailBG } from '../elements/DynamicThumbnailBG';
import { IProduct } from '../../../../../interfaces/product/IProduct';
import { Thumbnail } from '../../thumbnail/Thumbnail';

interface ITemplateSingleDynamic {
    thumbnail: IDynamicThumbnail;
    product: IProduct;
    showIconBar?: boolean;
}

export const TemplateDoubleUniversalStraightVertical: React.FC<ITemplateSingleDynamic> = (
    props
) => {
    const { thumbnail, product, showIconBar } = props;

    const heightStyles = useMemo((): string => {
        if (showIconBar) {
            return 'h-[calc(100%-30px)] gap-[4%] p-[2%]';
        }
        return 'h-full p-0 gap-0';
    }, [showIconBar]);

    return (
        <>
            <DynamicThumbnailBG thumbnail={thumbnail} />

            <div className={`relative grid aspect-[1/1] w-full grid-rows-2 ${heightStyles}`}>
                <div className="relative h-full w-full p-[4%]">
                    {product.thumbnails[0] && (
                        <Thumbnail
                            media={product.thumbnails[0]}
                            shadowAndBorder={true}
                            style={{
                                maxWidth: '80%',
                                // maxHeight: '40%',
                                transformOrigin: 'center',
                                zIndex: 2,
                            }}
                        />
                    )}
                </div>
                <div className="relative h-full w-full p-[4%]">
                    {product.thumbnails[1] && (
                        <Thumbnail
                            media={product.thumbnails[1]}
                            shadowAndBorder={true}
                            style={{
                                maxWidth: '80%',
                                // maxHeight: '40%',
                                transformOrigin: 'center',
                                zIndex: 2,
                            }}
                        />
                    )}
                </div>
            </div>
        </>
    );
};
