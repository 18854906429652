import { useApi } from '../../../../general/hooks/api/useApi';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ICategoryUpdateInput } from '../../../../../interfaces/category/api/ICategoryUpdateInput';
import { ICategoryDB } from '../../../../../interfaces/category/ICategory';
import { gql } from 'graphql-request';

export const useCategoryUpdate = () => {
    const { postGql } = useApi();

    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (input: ICategoryUpdateInput) => {
            const {
                categoryUpdate: { data, variables },
            } = await postGql<{
                categoryUpdate: { data: ICategoryDB; variables: { isSuccess: boolean } };
            }>(
                'categoryUpdate',
                gql`
                    mutation ($input: CategoryUpdateInput!) {
                        categoryUpdate(input: $input) {
                            id
                            name
                            description
                            accessRoleIDs
                            tenantIDs
                            productIDs
                            nameTranslation {
                                baseTranslation
                                languageCode
                                translationKey
                                group
                                tags
                                translations {
                                    languageCode
                                    translation
                                }
                            }
                        }
                    }
                `,
                { input }
            );
            return { data, variables };
        },
        onSuccess: () => {
            const key = [`categories`];
            void queryClient.invalidateQueries({ queryKey: key });
        },
    });
};
