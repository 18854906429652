import React from 'react';
import { EDynamicThumbnailBG } from '../enums/dynamicThumbnails.enums';
import { IDynamicThumbnail } from '../interfaces/dynamicThumbnail.interfaces';

interface IDynamicThumbnailBG {
    thumbnail: IDynamicThumbnail;
}

export const DynamicThumbnailBG: React.FC<IDynamicThumbnailBG> = (props) => {
    const { thumbnail } = props;

    return (
        <>
            {thumbnail.background && thumbnail.background !== EDynamicThumbnailBG.none && (
                <img
                    className="absolute inset-0 h-full w-full object-cover"
                    src={`/assets/thumbnailBackgrounds/${thumbnail.background}.jpg`}
                    alt="background"
                />
            )}
        </>
    );
};
