import { atom, DefaultValue, selector } from 'recoil';
import { ICategory } from '../../../../../interfaces/category/ICategory';

export interface IAdminCategoryState {
    categoryId: string | null;
    showArchived: boolean;
    categories: ICategory[];
    formKey: string | null;
    categoriesAll?: ICategory[];
    showTopLevelCategoriesSorter: boolean;
}

export const adminCategoryState = atom<IAdminCategoryState>({
    key: `admin-category-state-state`,
    default: {
        categoryId: null,
        categoriesAll: [],
        showArchived: false,
        categories: [],
        formKey: null,
        showTopLevelCategoriesSorter: false,
    },
});

export const adminCategories = selector<ICategory[]>({
    key: `/admin-categories-selector`,
    get: ({ get }): ICategory[] => {
        return get(adminCategoryState).categories;
    },
    set: ({ set }, newValue) => {
        set(adminCategoryState, (oldValue) => {
            return {
                ...oldValue,
                categories: newValue instanceof DefaultValue ? [] : newValue,
            };
        });
    },
});

export const adminCategoriesAll = selector<ICategory[]>({
    key: `/admin-categories-all-selector`,
    get: ({ get }): ICategory[] => {
        return get(adminCategoryState).categoriesAll || [];
    },
    set: ({ set }, newValue) => {
        set(adminCategoryState, (oldValue) => {
            return {
                ...oldValue,
                categoriesAll: newValue instanceof DefaultValue ? [] : newValue,
            };
        });
    },
});

export const adminShowTopLevelCategoriesSorter = selector<boolean>({
    key: 'admin-show-toplevel-categories-sorter',
    get: ({ get }): boolean => {
        return get(adminCategoryState).showTopLevelCategoriesSorter;
    },
    set: ({ set }, newValue) => {
        set(adminCategoryState, (oldValue) => {
            return {
                ...oldValue,
                showTopLevelCategoriesSorter: newValue instanceof DefaultValue ? false : newValue,
            };
        });
    },
});
