import React, { useMemo } from 'react';
import { IMedia } from '../../../../interfaces/cart/productInCart';
import { imagePathSanitizer } from '../../../../helper/imagePathSanitizer';

export interface IThumbnail {
    media: IMedia;
    width?: number;
    shadowAndBorder?: boolean;
    style?: React.CSSProperties;
    onMouseOver?: () => void;
}

export const Thumbnail: React.FC<IThumbnail> = (props) => {
    const { media, width, shadowAndBorder, style, onMouseOver } = props;

    const imagePath = useMemo(() => {
        if (!media) return null;

        return imagePathSanitizer(media.path ?? media.url);
    }, [media]);

    const mediaRatio = useMemo(() => {
        if (!media) return null;

        // possible case for svg
        if (media.height === 0) return 1;

        return media.width / media.height;
    }, [media]);

    const isSvgFile = useMemo(() => {
        if (imagePath) {
            return /.svg($|\?)/.test(imagePath);
        }
        return undefined;
    }, [imagePath]);

    const leadingDimension: string = useMemo(() => {
        if (media?.width > media?.height) {
            return 'w-[90%]';
        }
        return 'h-[90%]';
    }, [media?.height, media?.width]);

    const shadowAndBorderStyles: string = useMemo(() => {
        if (shadowAndBorder) {
            return 'border border-neutral-300 shadow-2xl';
        }
        return '';
    }, [shadowAndBorder]);

    return (
        <div className="relative flex h-full w-full items-center justify-center p-[4%]">
            {imagePath && isSvgFile && mediaRatio && (
                <img
                    src={imagePath}
                    alt={media.alternativeText ?? ''}
                    className="z-10 object-contain"
                    width={width ? width : media.width}
                    height={width ? width / mediaRatio : media.height}
                />
            )}

            {imagePath && !isSvgFile && mediaRatio && (
                <img
                    src={imagePath}
                    alt={media.alternativeText ?? ''}
                    className={`z-10 object-contain ${leadingDimension} ${shadowAndBorderStyles}`}
                    style={{
                        aspectRatio: `${media.width}/${media.height}`,
                        ...style,
                    }}
                    onMouseOver={onMouseOver}
                />
            )}
        </div>
    );
};
