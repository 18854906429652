import { IDynamicThumbnail } from '../interfaces/dynamicThumbnail.interfaces';
import { EDynamicThumbnailBG } from '../enums/dynamicThumbnails.enums';

export const dynamicThumbnailIconColor = (background: IDynamicThumbnail['background']) => {
    // List all with dark background.
    switch (background) {
        case EDynamicThumbnailBG.WOODEN01:
        case EDynamicThumbnailBG.WOODEN02:
        case EDynamicThumbnailBG.WOODEN03:
            return 'text-neutral-50';
        default:
            return 'text-neutral-500';
    }
};
