import { ICategory, ICategoryDB } from './ICategory';
import { IProduct } from '../product/IProduct';

export const mapCategoryFromDBToFE = (category: ICategoryDB): ICategory => {
    return {
        id: category.id,
        name: category.name,
        description: category.description,

        parentCategoryID: category.parentCategoryID ?? undefined,
        childrenCategoryIDs: (category.children as ICategoryDB[])?.map((child) => child.id) ?? [],

        children: (category.children as ICategory[]) ?? [],
        parent: category.parent ? mapCategoryFromDBToFE(category.parent) : undefined,

        tenantIDs: category.tenantIDs ?? [],

        translationID: category.translationID ?? undefined,
        orderNo: category.orderNo ?? undefined,
        accessRoleIDs: category.accessRoleIDs ?? [],
        published: category.published ?? undefined,
        code: category.code ?? undefined,
        accessLevel: category.accessLevel ?? undefined,
        productIDs: (category.productIDs as string[]) ?? [],
        products: (category.products as IProduct[]) ?? [],
        presentationConfig: category.presentationConfig
            ? (JSON.parse(category.presentationConfig) as
                  | ICategory['presentationConfig']
                  | undefined)
            : undefined,

        label: category.name, // ClientApp specific => basically that is name... TODO - refactor label into name
        image: null, //ClientApp Specific, todo fix that later, looks like it shoud not be there??
        nameTranslation: category.nameTranslation ?? undefined,
        descriptionTranslation: category.descriptionTranslation ?? undefined,
    };
};
