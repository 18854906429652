import React from 'react';
import { IDynamicThumbnail } from '../interfaces/dynamicThumbnail.interfaces';
import { DynamicThumbnailBG } from '../elements/DynamicThumbnailBG';
import { IProduct } from '../../../../../interfaces/product/IProduct';
import { Thumbnail } from '../../thumbnail/Thumbnail';

interface ITemplateSingleDynamic {
    thumbnail: IDynamicThumbnail;
    product: IProduct;
}

export const TemplateSingleUniversalPerspective: React.FC<ITemplateSingleDynamic> = (props) => {
    const { thumbnail, product } = props;

    return (
        <>
            <DynamicThumbnailBG thumbnail={thumbnail} />

            {product.thumbnails[0] && (
                <Thumbnail
                    media={product.thumbnails[0]}
                    shadowAndBorder={true}
                    style={{
                        maxWidth: '80%',
                        maxHeight: '80%',
                        transform: 'perspective(600px) rotateY(15deg) rotateX(5deg) skewY(-2deg)',
                        transformOrigin: 'center',
                        top: '18%',
                        left: '12%',
                        zIndex: 2,
                    }}
                />
            )}
        </>
    );
};
