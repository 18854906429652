import { ICategory, ICategoryDB } from '../../../../../interfaces/category/ICategory';
import { gql } from 'graphql-request';
import { useApi } from '../../../../general/hooks/api/useApi';
import { useQuery } from '@tanstack/react-query';
import { mapCategoryFromDBToFE } from '../../../../../interfaces/category/maps';
import { useCallback } from 'react';

export const useCategoriesAdminQuery = () => {
    const { postGql } = useApi();

    const mapCategoriesRecursive = useCallback(
        (categories: ICategoryDB[], category: ICategoryDB): ICategory => {
            // modifying ICategoryDB to ICategory here
            // TODO !!! Needs to be mapped correctly
            // @ts-ignore
            category.children = categories
                .filter((cat) => cat.parentCategoryID === category.id)
                .map((cat) => mapCategoriesRecursive(categories, cat));
            return mapCategoryFromDBToFE(category);
        },
        []
    );

    return useQuery<ICategory[]>({
        // indicating that it's returning ICategory[]
        staleTime: 60000,
        queryKey: ['categoriesAdmin'],
        queryFn: async () => {
            const { categoriesAdmin } = await postGql<{ categoriesAdmin: ICategoryDB[] }>(
                'categoriesAdmin',
                gql`
                    query categoriesAdmin($input: CategoriesGetInput!) {
                        categoriesAdmin(input: $input) {
                            id
                            name
                            description
                            parentCategoryID
                            children {
                                id
                                name
                                description
                            }
                            orderNo
                            accessRoleIDs
                            published
                            code
                            productIDs
                            tenantIDs
                        }
                    }
                `,
                { input: {} }
            );

            return categoriesAdmin.map((cat) => mapCategoriesRecursive(categoriesAdmin, cat));
        },
    });
};
