import { atom, DefaultValue, selector } from 'recoil';
import { ICategory } from '../../../../../interfaces/category/ICategory';
import { IProduct } from '../../../../../interfaces/product/IProduct';
import { IItem } from '../../../../../interfaces/item/IItem';

export interface ICatalogState {
    products: IProduct[] | null;
    categories?: ICategory[];
    categoryId?: string;
    categoryProducts?: IProduct[];
    productId?: string;
    selectedProduct?: IProduct;
    currentItem?: IItem;
    editProductId: string | null;
}

export const catalogState = atom<ICatalogState>({
    key: `catalog-state`,
    default: {
        categories: [],
        products: null,
        categoryId: undefined,
        categoryProducts: [],
        productId: undefined,
        selectedProduct: {} as IProduct,
        currentItem: {} as IItem,
        editProductId: null,
    },
});

export const productsSelector = selector<IProduct[] | null>({
    key: 'catalog-productsSelector',
    get: ({ get }) => {
        return get(catalogState).products;
    },
    set: ({ set }, newValue) =>
        set(
            catalogState,
            newValue instanceof DefaultValue
                ? newValue
                : (currVal) => {
                      return { ...currVal, products: newValue };
                  }
        ),
});

export const productSelector = (productId: string) =>
    selector<IProduct | undefined>({
        key: `catalog-productSelector-${productId}`,
        get: ({ get }) => {
            return get(catalogState).products?.find((product) => product.id === productId);
        },
    });

export const productIdSelector = selector<string | undefined>({
    key: 'catalog-productIdSelector',
    get: ({ get }) => {
        return get(catalogState).productId;
    },
    set: ({ set }, newValue) => {
        set(
            catalogState,
            newValue instanceof DefaultValue
                ? newValue
                : (currVal) => {
                      return { ...currVal, productId: newValue };
                  }
        );
    },
});

export const selectedProductSelector = selector<IProduct | undefined>({
    key: 'catalog-selectedProductSelector',
    get: ({ get }) => {
        return get(catalogState).selectedProduct;
    },
    set: ({ set }, newValue) => {
        set(
            catalogState,
            newValue instanceof DefaultValue
                ? newValue
                : (currVal) => {
                      return { ...currVal, selectedProduct: newValue };
                  }
        );
    },
});

export const categoriesSelector = selector<ICategory[]>({
    key: 'catalog-categoriesSelector',
    get: ({ get }) => {
        return get(catalogState).categories ?? [];
    },
    set: ({ set }, newValue) =>
        set(
            catalogState,
            newValue instanceof DefaultValue
                ? newValue
                : (currVal) => {
                      return { ...currVal, categories: newValue };
                  }
        ),
});

export const categoryIdSelector = selector<string | undefined>({
    key: `catalog-categoryIdSelector`,
    get: ({ get }) => {
        return get(catalogState).categoryId;
    },
    set: ({ set }, newValue) =>
        set(
            catalogState,
            newValue instanceof DefaultValue
                ? newValue
                : (currVal) => {
                      return { ...currVal, categoryId: newValue };
                  }
        ),
});

export const categoryProductsSelector = selector<IProduct[]>({
    key: `catalog-categoryProductsSelector`,
    get: ({ get }) => {
        return get(catalogState).categoryProducts ?? [];
    },
    set: ({ set }, newValue) =>
        set(
            catalogState,
            newValue instanceof DefaultValue
                ? newValue
                : (currVal) => {
                      return { ...currVal, categoryProducts: newValue };
                  }
        ),
});

export const currentItemSelector = selector<IItem | undefined>({
    key: `catalog-currentItemSelector`,
    get: ({ get }) => {
        return get(catalogState).currentItem;
    },
    set: ({ set }, newValue) =>
        set(
            catalogState,
            newValue instanceof DefaultValue
                ? newValue
                : (currVal) => {
                      return { ...currVal, currentItem: newValue };
                  }
        ),
});

export const editProductIdSelector = selector<string | null>({
    key: `catalog-editProductIdSelector`,
    get: ({ get }) => {
        return get(catalogState).editProductId;
    },
    set: ({ set }, newValue) =>
        set(
            catalogState,
            newValue instanceof DefaultValue
                ? newValue
                : (currVal) => {
                      return { ...currVal, editProductId: newValue };
                  }
        ),
});
