import { IPriceTable, IPriceTableDB } from './IPriceTable';
import { IProduct, IProductDB } from './IProduct';
import { IMedia } from '../cart/productInCart';
import { EProduct } from '../../enums/product.enums';
import { IDynamicThumbnail } from '../../components/general/elements/DynamicThumbnail/interfaces/dynamicThumbnail.interfaces';
import {
    EDynamicThumbnailBG,
    EDynamicThumbnailTemplate,
} from '../../components/general/elements/DynamicThumbnail/enums/dynamicThumbnails.enums';

interface IProductMapOptions {
    mapVariants?: boolean;
    mapParent?: boolean;
}

export const mapThumbnailConfigFromDB = (
    thumbnailConfig: string | undefined
): IDynamicThumbnail => {
    const defaultConfig = {
        template: EDynamicThumbnailTemplate.none,
        background: EDynamicThumbnailBG.none,
        properties: {},
    };

    if (!thumbnailConfig) {
        return defaultConfig;
    }

    const config: IDynamicThumbnail = JSON.parse(thumbnailConfig) as unknown as IDynamicThumbnail;

    if ('template' in config) {
        return config;
    }

    /**
     * Create Default Thumbnail Config
     */
    return defaultConfig;
};

export const mapPriceTableFromDBtoFE = (priceTable: IPriceTableDB): IPriceTable => {
    return {
        id: priceTable.id,
        name: priceTable.name,
        cartonQuantity: priceTable.cartonQuantity,
        cartonWeight: priceTable.cartonWeight,
        archived: priceTable.archived,
        scales: priceTable.scales,
        options: priceTable.options,
        points: priceTable.points,
        keys: priceTable.keys,
        productIDs: priceTable.productIDs,
    };
};

export const mapProductFromDBToFE = (
    product: IProductDB,
    options?: IProductMapOptions
): IProduct => {
    const domain = process.env.NEXT_PUBLIC_API_ENDPOINT;

    const newProduct: IProduct = {
        id: product.id,
        code: product.code,
        type: product.type as EProduct,
        name: product.name,
        published: product.published ?? null,
        created: product.created,
        fields: product.fields,
        orderable: product.orderable ?? false,
        hidePlaceControls: product.hidePlaceControls ?? false,

        staticLinkURL: product.staticLinkURL,

        isFavourite: product.isFavourite,

        tenantIDs: product.tenantIDs,

        quantityOptions: { ...product.quantityOptions },

        accessRoleIDs: product.accessRoleIDs,
        categoryIDs: product.categoryIDs,
        priceTableID: product.priceTableID ?? undefined,

        deleted: product.deleted,

        priceTable: product.priceTable ?? undefined,

        parentProductID: product.parentProductID,

        categories: product.categories ? product.categories : [],

        printable: false, // todo fix that
        itemName: product.name, // todo merge with name

        archived: product.archived,

        // TODO obsolete?
        imagesAll: product.images,
        images: product.images,

        labels: product.labels,

        thumbnails: product.images.filter((image) => {
            if (image.tag === 'thumbnail') {
                return {
                    id: image.id,
                    width: image.width,
                    height: image.height,
                    // todo check if need to do that here or in hook
                    path: domain ? `${domain}${image.path}` : image.path,
                    tag: image.tag,
                    url: domain ? `${domain}${image.path}` : image.path,
                } as IMedia;
            }
        }),

        description: product.description ? product.description : null,

        stock: product.stock,
        thumbnailConfig: mapThumbnailConfigFromDB(product.thumbnailConfig ?? ''),
        nameTranslation: product.nameTranslation,
        descriptionTranslation: product.descriptionTranslation,
        hideItemName: product.hideItemName ?? false,
        defaultTemplateFile: product.defaultTemplateFile,
        defaultOutputPipeline: product.defaultOutputPipeline,

        variantProductIDs: product.variantProductIDs,
        descriptionFromParent: product.descriptionFromParent,
    };

    if (options?.mapParent) {
        if (product.parent) {
            newProduct.parent = mapProductFromDBToFE(product.parent as IProductDB, {
                mapVariants: true,
                mapParent: false,
            });
        }
    }

    if (options?.mapVariants) {
        newProduct.variants = product.variants?.map((variant) =>
            mapProductFromDBToFE(variant, { mapVariants: false, mapParent: false })
        );
    }

    return newProduct;
};
