import { ICategoryDB } from '../../../../interfaces/category/ICategory';
import { gql } from 'graphql-request';
import { useApi } from '../../../general/hooks/api/useApi';
import { useQuery } from '@tanstack/react-query';
import { mapCategoryFromDBToFE } from '../../../../interfaces/category/maps';

export const useCategoryQuery = (id: string) => {
    const { postGql } = useApi();
    return useQuery({
        queryKey: ['category', id],
        enabled: !!id,
        queryFn: async () => {
            const { category } = await postGql<{ category: ICategoryDB }>(
                'category',
                gql`
                    query category($id: ID!) {
                        category(id: $id) {
                            id
                            name
                            description
                            parentCategoryID
                            products {
                                id
                                name
                                description
                                images {
                                    height
                                    width
                                    path
                                    tag
                                    id
                                }
                                created
                                priceTableID

                                nameTranslation {
                                    baseTranslation
                                    translationKey
                                    languageCode
                                    translations {
                                        languageCode
                                        translation
                                    }
                                }
                                nameTranslation {
                                    languageCode
                                    translationKey
                                    baseTranslation
                                }
                                images {
                                    height
                                    width
                                    path
                                    tag
                                    id
                                }
                            }
                            children {
                                id
                                name
                                description
                                productIDs
                            }
                            parent {
                                id
                                name
                                description
                            }
                            orderNo
                            accessRoleIDs
                            published
                            code
                            productIDs
                            tenantIDs
                            presentationConfig
                            nameTranslation {
                                baseTranslation
                                translationKey
                                languageCode
                                group
                                tags
                                translations {
                                    languageCode
                                    translation
                                }
                            }
                            descriptionTranslation {
                                baseTranslation
                                translationKey
                                languageCode
                                translations {
                                    languageCode
                                    translation
                                }
                            }
                        }
                    }
                `,
                { id }
            );
            return mapCategoryFromDBToFE(category);
        },
        staleTime: 60000,
    });
};
