import React, { useState } from 'react';
import { IDynamicThumbnail } from '../interfaces/dynamicThumbnail.interfaces';
import { DynamicThumbnailBG } from '../elements/DynamicThumbnailBG';
import { IProduct } from '../../../../../interfaces/product/IProduct';
import { Thumbnail } from '../../thumbnail/Thumbnail';

interface ITemplateSingleDynamic {
    thumbnail: IDynamicThumbnail;
    product: IProduct;
}

export const TemplateDoubleUniversalStraight: React.FC<ITemplateSingleDynamic> = (props) => {
    const { thumbnail, product } = props;

    const [order, setOrder] = useState<number[]>([2, 1]);

    return (
        <>
            <DynamicThumbnailBG thumbnail={thumbnail} />

            <div className="relative flex h-full w-full items-center justify-center transition-all">
                <div className="absolute h-full w-full">
                    {product.thumbnails[0] && (
                        <Thumbnail
                            media={product.thumbnails[0]}
                            shadowAndBorder={true}
                            onMouseOver={() => {
                                setOrder([2, 1]);
                            }}
                            style={{
                                transition: 'all 0.5s',
                                translate: '10% 15%',
                                position: 'relative',
                                maxWidth: '80%',
                                maxHeight: '70%',
                                transformOrigin: 'center',
                                zIndex: order[0],
                            }}
                        />
                    )}
                </div>
                <div className="absolute h-full w-full">
                    {product.thumbnails[1] && (
                        <Thumbnail
                            media={product.thumbnails[1]}
                            shadowAndBorder={true}
                            onMouseOver={() => {
                                setOrder([1, 2]);
                            }}
                            style={{
                                transition: 'all 0.5s',
                                translate: '-8% -4%',
                                position: 'relative',
                                maxWidth: '80%',
                                maxHeight: '70%',
                                transformOrigin: 'center',
                                zIndex: order[1],
                            }}
                        />
                    )}
                </div>
            </div>
        </>
    );
};
