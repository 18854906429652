export enum EDynamicThumbnailTemplate {
    'none' = 'none',
    SingleUniversalPerspective = 'SingleUniversalPerspective',
    SingleUniversalStraight = 'SingleUniversalStraight',
    DoubleUniversalStraight = 'DoubleUniversalStraight',
    DoubleUniversalStraightVertical = 'DoubleUniversalStraightVertical',
    BC1P01 = 'BC1P01', // Businesscard 1 Page 1
    BC2P01 = 'BC2P01', // Businesscard 2 Page 1
    BC2P03 = 'BC2P03',
}

/**
 * This enum is the readable description of EDynamicThumbnailTemplate
 */
export enum EDynamicThumbnailTemplateLabels {
    'none' = 'keine',
    'SingleUniversalPerspective' = 'Universal Perspektive',
    'SingleUniversalStraight' = 'Universal',
    'DoubleUniversalStraight' = 'Universal Doppelt',
    'DoubleUniversalStraightVertical' = 'Universal Doppelt Vertikal',
    'BC1P01' = 'VK 1 Seite',
    'BC2P01' = 'VK 2 Seiten',
    'BC2P03' = 'VK 2 Stage',
}

export enum EDynamicThumbnailBG {
    none = 'none',
    NEUTRAL01 = 'NEUTRAL01',
    NEUTRAL02 = 'NEUTRAL02',
    WOODEN01 = 'WOODEN01',
    WOODEN02 = 'WOODEN02',
    WOODEN03 = 'WOODEN03',
    OFFICE01 = 'OFFICE01',
    OFFICE02 = 'OFFICE02',
    OFFICE03 = 'OFFICE03',
    OFFICE04 = 'OFFICE04',
    OFFICE05 = 'OFFICE05',
}

export enum EDynamicThumbnailBGLabels {
    'none' = 'kein Hintergrund',
    'NEUTRAL01' = 'Neutral 1',
    'NEUTRAL02' = 'Neutral 2',
    'WOODEN01' = 'Holz 1',
    'WOODEN02' = 'Holz 2',
    'WOODEN03' = 'Holz 3',
    'OFFICE01' = 'Büro 1',
    'OFFICE02' = 'Büro 2',
    'OFFICE03' = 'Büro 3',
    'OFFICE04' = 'Büro 4',
    'OFFICE05' = 'Büro 5',
}

export enum EDynamicThumbnailNoBGList {
    BC2P03 = 'BC2P03',
}

export enum EDynamicThumbnailCategories {
    'Straight' = 'Straight',
    'Perspektive' = 'Perspective',
    'Scene' = 'Scene',
}

export const templateCategoriesMap = {
    none: EDynamicThumbnailCategories.Straight,
    SingleUniversalPerspective: EDynamicThumbnailCategories.Perspektive,
    SingleUniversalStraight: EDynamicThumbnailCategories.Straight,
    DoubleUniversalStraight: EDynamicThumbnailCategories.Straight,
    DoubleUniversalStraightVertical: EDynamicThumbnailCategories.Straight,
    BC1P01: EDynamicThumbnailCategories.Perspektive,
    BC2P01: EDynamicThumbnailCategories.Perspektive,
    BC2P03: EDynamicThumbnailCategories.Scene,
};

export enum EDynamicThumbnailRatio {
    'landscape' = 'landscape',
    'portrait' = 'portrait',
    'square' = 'square',
}

export interface ITemplateAllowedRatios {
    [key: string]: EDynamicThumbnailRatio[];
}

export const templateAllowedRatios: ITemplateAllowedRatios = {
    none: [
        EDynamicThumbnailRatio.landscape,
        EDynamicThumbnailRatio.portrait,
        EDynamicThumbnailRatio.square,
    ],
    SingleUniversalPerspective: [
        EDynamicThumbnailRatio.landscape,
        EDynamicThumbnailRatio.portrait,
        EDynamicThumbnailRatio.square,
    ],
    SingleUniversalStraight: [
        EDynamicThumbnailRatio.landscape,
        EDynamicThumbnailRatio.portrait,
        EDynamicThumbnailRatio.square,
    ],
    DoubleUniversalStraight: [
        EDynamicThumbnailRatio.landscape,
        EDynamicThumbnailRatio.portrait,
        EDynamicThumbnailRatio.square,
    ],
    DoubleUniversalStraightVertical: [
        EDynamicThumbnailRatio.landscape,
        EDynamicThumbnailRatio.square,
    ],
    BC1P01: [EDynamicThumbnailRatio.landscape],
    BC2P01: [EDynamicThumbnailRatio.landscape],
    BC2P03: [EDynamicThumbnailRatio.landscape],
};
