import React, { useEffect, useMemo, useRef, useState } from 'react';
import { IProductLabel, TProductLabelColor } from '../../../../../interfaces/product/IProductLabel';
import { useThemeStyles } from '../../../theme/TenantTheme/hooks/useThemeStyles';
import { MicroButton } from '../../../controls/button/MicroButton';
import {
    CheckIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    EllipsisVerticalIcon,
    TrashIcon,
} from '@heroicons/react/16/solid';
import { ETranslationType } from '../../../Translations/enum/translations.enums';
import { PopoverMenu } from '../../popOverMenu/PopoverMenu';
import { ClockIcon } from '@heroicons/react/24/outline';
import dayjs from 'dayjs';
import { ModalBorderless } from '../../../modals/ModalBorderless';
import { TextField } from '../../../controls/texfield/TextField';
import { Button } from '../../../controls/button/Button';
import { Infobox } from '../../../display/infobox/Infobox';
import { ITranslations } from '../../../../../interfaces/translation/translations.interfaces';
import { useTranslationApply } from '../../../Translations/hooks/useTranslationApply';
import { useAdminControlsState } from '../../adminControls/state/adminControls.state';
import { useTranslationGetContent } from '../../../Translations/hooks/useTranslationGetContent';

export interface IProductLabelElement {
    label: IProductLabel;
    editable?: boolean;
    isFirst?: boolean;
    isLast?: boolean;
    handleRemove?: () => void;
    handleMoveLeft?: () => void;
    handleMoveRight?: () => void;
    handleSetColor?: (color: TProductLabelColor) => void;
    handleChangeName?: (name: string) => void;
    translationKey?: string; // ProductID
    translations?: ITranslations; // product.labels[].nameTranslations
}

export const ProductLabel: React.FC<IProductLabelElement> = (props) => {
    const {
        label,
        editable = false,
        isLast = false,
        isFirst = false,
        handleRemove,
        handleMoveRight,
        handleMoveLeft,
        handleSetColor,
        handleChangeName,
        translationKey,
        // translations - removed as it's no longer needed
    } = props;

    const { applyStyles } = useThemeStyles();

    const inputRef = useRef<HTMLInputElement>(null);
    const spanRef = useRef<HTMLSpanElement>(null);

    const { applyTranslation } = useTranslationApply();
    const { getTranslationContent } = useTranslationGetContent();

    const { languageControls } = useAdminControlsState();

    const [showModal, setShowModal] = useState(false);

    const color: string = useMemo(() => {
        switch (label.color) {
            case 'primary':
                return 'bg-primary text-white';
            case 'primary-light':
                return 'bg-primary-light text-white';
            case 'primary-dark':
                return 'bg-primary-dark text-white';
            case 'secondary':
                return 'bg-secondary text-white';
            case 'success':
                return 'bg-success text-white';
            case 'danger':
                return 'bg-danger text-white';
            case 'neutral':
            default:
                return 'bg-neutral-500 text-white';
        }
    }, [label.color]);

    useEffect(() => {
        if (spanRef.current && inputRef.current) {
            inputRef.current.style.width = `${spanRef.current.offsetWidth}px`;
        }
    }, [label.name]);

    const startsAtDate: string = useMemo(() => {
        if (label.startsAt) {
            return dayjs(label.startsAt).format('DD.MM.YYYY');
        }

        return 'immer';
    }, [label.startsAt]);

    const endsAtDate: string = useMemo(() => {
        if (label.expiresAt) {
            return dayjs(label.expiresAt).format('DD.MM.YYYY');
        }

        return 'immer';
    }, [label.expiresAt]);

    return (
        <div
            className={`relative flex flex-row gap-1 justify-center items-center py-1 px-3 rounded-lg font-semibold text-sm ${color}`}
            style={{ ...applyStyles({ rounded: 'light' }) }}
            onClick={(event) => {
                if (languageControls) {
                    // Prevent Click-Through to CatalogItem while translation
                    event.stopPropagation();
                }
            }}
        >
            {!isFirst && editable && (
                <MicroButton
                    onClick={() => {
                        handleMoveLeft?.();
                    }}
                    icon={<ChevronLeftIcon className="h-4" />}
                />
            )}

            {languageControls ? (
                <span
                    {...applyTranslation({
                        key: [translationKey || '', label.labelID || ''],
                        type: ETranslationType.productLabelName,
                    })}
                >
                    {label.nameTranslation 
                        ? getTranslationContent(label.nameTranslation, label.name)
                        : label.name}
                </span>
            ) : (
                label.nameTranslation 
                    ? getTranslationContent(label.nameTranslation, label.name)
                    : label.name
            )}
            {editable && (
                <>
                    <input
                        ref={inputRef}
                        type="text"
                        value={label.name}
                        onChange={(event) => {
                            handleChangeName?.(event.target.value);
                        }}
                        className="m-0 border-0 bg-transparent p-0 text-sm ring-white transition-colors focus:ring-1 focus:bg-white/20"
                        style={{ width: 'auto' }}
                    />
                    {/* Hidden span for measuring text width */}
                    <span ref={spanRef} className="invisible absolute whitespace-pre text-sm">
                        {label.name}
                    </span>
                </>
            )}

            {!isLast && editable && (
                <MicroButton
                    onClick={() => {
                        handleMoveRight?.();
                    }}
                    icon={<ChevronRightIcon className="h-4" />}
                />
            )}

            {editable && (
                <PopoverMenu
                    items={[
                        {
                            label: 'Farbe Primär',
                            icon: <div className="h-4 w-6 bg-primary" />,
                            onClick: () => {
                                handleSetColor?.('primary');
                            },
                        },
                        {
                            label: 'Farbe Primär Hell',
                            icon: <div className="h-4 w-6 bg-primary-light" />,
                            onClick: () => {
                                handleSetColor?.('primary-light');
                            },
                        },
                        {
                            label: 'Farbe Primär Dunkel',
                            icon: <div className="h-4 w-6 bg-primary-dark" />,
                            onClick: () => {
                                handleSetColor?.('primary-dark');
                            },
                        },
                        {
                            label: 'Farbe Sekundär',
                            icon: <div className="h-4 w-6 bg-secondary" />,
                            onClick: () => {
                                handleSetColor?.('secondary');
                            },
                        },
                        {
                            label: 'Farbe Erfolg',
                            icon: <div className="h-4 w-6 bg-success" />,
                            onClick: () => {
                                handleSetColor?.('success');
                            },
                        },
                        {
                            label: 'Farbe Gefahr',
                            icon: <div className="h-4 w-6 bg-danger" />,
                            onClick: () => {
                                handleSetColor?.('danger');
                            },
                        },
                        {
                            label: 'Farbe Neutral',
                            icon: <div className="h-4 w-6 bg-neutral-500" />,
                            onClick: () => {
                                handleSetColor?.('neutral');
                            },
                        },
                        {
                            label: `Ab: ${startsAtDate}`,
                            icon: <ClockIcon className="text-neutral-500" />,
                            onClick: () => {
                                setShowModal(true);
                            },
                        },
                        {
                            label: `Bis: ${endsAtDate}`,
                            icon: <ClockIcon className="text-neutral-500" />,
                            onClick: () => {
                                setShowModal(true);
                            },
                        },
                        {
                            label: 'Delete',
                            icon: <TrashIcon className="h-5 text-danger" />,
                            onClick: () => {
                                if (confirm('Soll dieses Label wirklich gelöscht werden?')) {
                                    handleRemove?.();
                                }
                            },
                        },
                    ]}
                    openToSide={'right'}
                    testId="product-labels"
                    openToVertical={'down'}
                >
                    <EllipsisVerticalIcon className="h-4 text-white" />
                </PopoverMenu>
            )}

            <ModalBorderless
                show={showModal}
                afterClose={() => {
                    setShowModal(false);
                }}
                title="Test"
                closeable={true}
            >
                <div className="relative z-50 flex flex-col gap-4 p-8 w-[300px] h-[500px]">
                    <Infobox small={true}>Achtung, diese Funktion ist noch nicht fertig.</Infobox>

                    <TextField type="date" label="Anzeige ab" value={label.startsAt} />
                    <TextField type="date" label="Anzeige bis" value={label.expiresAt} />
                    <Infobox>
                        Wenn keine Werte angegeben sind, wird das Label immer angezeigt.
                    </Infobox>
                    <div className="relative flex flex-row gap-1">
                        <Button
                            onClick={() => {
                                setShowModal(false);
                            }}
                            color="neutral"
                            wrapperCss="grow"
                        >
                            Schließen
                        </Button>
                        <Button
                            onClick={() => {}}
                            color="success"
                            wrapperCss="grow"
                            iconStart={<CheckIcon />}
                        >
                            Speichern
                        </Button>
                    </div>
                </div>
            </ModalBorderless>
        </div>
    );
};
