import React from 'react';
import { IDynamicThumbnail } from '../interfaces/dynamicThumbnail.interfaces';
import { DynamicThumbnailBG } from '../elements/DynamicThumbnailBG';
import { IProduct } from '../../../../../interfaces/product/IProduct';
import { useSrcFromMedia } from '../hooks/useSrcFromMedia';

export interface ITemplateBC2P01 {
    thumbnail: IDynamicThumbnail;
    product: IProduct;
}

export const TemplateBC2P01: React.FC<ITemplateBC2P01> = (props) => {
    const { thumbnail, product } = props;

    const { imagePath } = useSrcFromMedia();

    return (
        <>
            <DynamicThumbnailBG thumbnail={thumbnail} />
            {/* Back Business card */}
            <img
                className="absolute border border-neutral-300 shadow-lg transition-all hover:scale-110"
                style={{
                    width: '75%',
                    height: '50%',
                    transform: 'perspective(600px) rotateY(-10deg) rotateX(8deg) skewY(-1deg)',
                    transformOrigin: 'center',
                    top: '15%',
                    left: '10%',
                    zIndex: 1,
                }}
                src={imagePath(product.thumbnails[1])}
                alt="back-thumbnail"
            />
            {/* Front Business card */}
            <img
                className="absolute border border-neutral-300 shadow-2xl transition-all"
                style={{
                    width: '80%',
                    height: '50%',
                    transform: 'perspective(600px) rotateY(15deg) rotateX(5deg) skewY(-2deg)',
                    transformOrigin: 'center',
                    top: '35%',
                    left: '15%',
                    zIndex: 2,
                }}
                src={imagePath(product.thumbnails[0])}
                alt="front-thumbnail"
            />
        </>
    );
};
