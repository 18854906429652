import { DocumentDuplicateIcon } from '@heroicons/react/24/outline';
import React, { useMemo } from 'react';
import { dynamicThumbnailIconColor } from '../data/dynamicThumbnailIconColor';
import { IDynamicThumbnail } from '../interfaces/dynamicThumbnail.interfaces';

interface IDynamicThumbnailIconBar {
    thumbnail: IDynamicThumbnail;
}

export const DynamicThumbnailIconBar: React.FC<IDynamicThumbnailIconBar> = (props) => {
    const { thumbnail } = props;

    const color = useMemo(() => {
        return dynamicThumbnailIconColor(thumbnail.background);
    }, [thumbnail.background]);

    return (
        <div className="absolute bottom-0 left-0 flex h-8 w-full flex-row justify-end gap-1 px-2">
            {thumbnail.properties?.pages && (
                <span className={`relative flex flex-row gap-1 ${color}`}>
                    <span>{thumbnail.properties?.pages}</span>
                    <DocumentDuplicateIcon className="h-6" />
                </span>
            )}
        </div>
    );
};
