import React, { useMemo } from 'react';
import { DynamicThumbnailIconBar } from './elements/DynamicThumbnailIconBar';
import { DynamicThumbnailLoader } from './elements/DynamicThumbnailLoader';
import { IDynamicThumbnail } from './interfaces/dynamicThumbnail.interfaces';
import { IProduct } from '../../../../interfaces/product/IProduct';
import { ProductLabel } from '../productLabelEditor/elements/ProductLabel';

/**
 * Dynamic Thumbnail Component
 * @param props
 * @constructor
 * @return JSX.Element
 * @property {IDynamicThumbnail} thumbnail - The thumbnail object
 * @property {boolean} showIconBar - Show the icon bar
 * @property {IProduct} product - The product object
 * @property {string | null} maxThumbnailSize - When provided a string like 290px, it will set the max width and height of the thumbnail. When null, it will set the max width and height to 290px.
 */
interface IDynamicThumbnailProps {
    thumbnail: IDynamicThumbnail;
    showIconBar?: boolean;
    showLabels?: boolean;
    product: IProduct;
    maxThumbnailSize?: string | null;
}

export const DynamicThumbnail: React.FC<IDynamicThumbnailProps> = (props) => {
    const { thumbnail, showIconBar = true, product, maxThumbnailSize, showLabels = false } = props;

    const maxSize = useMemo(() => {
        if (maxThumbnailSize) {
            return { maxWidth: maxThumbnailSize, maxHeight: maxThumbnailSize };
        }

        if (maxThumbnailSize === null) {
            return { maxWidth: '294px', maxHeight: '294px' };
        }

        return undefined;
    }, [maxThumbnailSize]);

    return (
        <div
            className="relative flex h-full w-full justify-center border border-neutral-300 bg-neutral-100 group aspect-[1/1]"
            style={{ ...maxSize }}
        >
            <DynamicThumbnailLoader
                thumbnail={thumbnail}
                product={product}
                showIconBar={showIconBar}
            />
            {showIconBar && <DynamicThumbnailIconBar thumbnail={thumbnail} />}

            {showLabels && product.labels && product.labels.length > 0 && (
                <div className="absolute top-0 left-0 z-20 flex w-full flex-row justify-end gap-1 p-2 pointer-events-auto">
                    {product.labels.map((label, index) => (
                        <ProductLabel
                            label={label}
                            editable={false}
                            key={`label-${index}`}
                            translations={label.nameTranslation}
                            translationKey={product.id}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};
