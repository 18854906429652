import { ICategory, ICategoryDB } from '../../../../interfaces/category/ICategory';
import { useEffect, useMemo } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { getCategoriesFilter } from '../../../admin/areas/categories/state/adminCategoriesState';
import { useCategoriesAdminQuery } from '../../../admin/areas/categories/hooks/useCategoriesAdminQuery';
import {
    adminCategories,
    adminCategoriesAll,
} from '../../../admin/areas/categories/state/adminCategoryState';
import { mapCategoryFromDBToFE } from '../../../../interfaces/category/maps';
import { tenantSelector } from '../../../general/state/tenantState';

const categoriesFilter = (category: ICategory, search: string) => {
    return (
        category.name?.toLowerCase().includes(search.toLowerCase()) ||
        category.description?.toLowerCase().includes(search.toLowerCase())
    );
};

export const useFilteredCategories = () => {
    const setCategories = useSetRecoilState(adminCategories);
    const setCategoriesAll = useSetRecoilState(adminCategoriesAll);

    const tenant = useRecoilValue(tenantSelector);

    const { data: categoriesAll, isFetching, refetch } = useCategoriesAdminQuery();

    const filter = useRecoilValue(getCategoriesFilter);

    const categoriesFiltered = useMemo<ICategory[]>(() => {
        if (!categoriesAll) {
            return new Array<ICategory>();
        }

        // Filter products by products.tenants === tenant.name
        // TODO !!! Needs to be mapped correctly - its not DB version
        const categoryDBs: ICategoryDB[] = (categoriesAll as ICategoryDB[]).filter((category) => {
            if (!category.tenantIDs) return false;
            if (category.parentCategoryID) return false;
            return category.tenantIDs.includes(tenant?.id as string);
        });

        if (filter) {
            return {
                ...categoryDBs
                    .filter((categoryDB) =>
                        categoriesFilter(mapCategoryFromDBToFE(categoryDB), filter)
                    )
                    .map((categoryDB) => mapCategoryFromDBToFE(categoryDB)),
            };
        }
        return categoryDBs.map((categoryDB) => mapCategoryFromDBToFE(categoryDB));
    }, [categoriesAll, filter, tenant?.id]);

    /**
     * Will update the products if the productsAll changes; into the recoil state
     * This is more reliable than useMemo rendering
     */
    useEffect(() => {
        if (categoriesAll) {
            setCategoriesAll(categoriesAll);
            setCategories(categoriesFiltered);
        }
    }, [categoriesAll, categoriesFiltered, setCategories, setCategoriesAll]);

    return { categories: categoriesFiltered, isFetching, refetch, categoriesAll };
};
