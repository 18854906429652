import React from 'react';
import { IDynamicThumbnail } from '../interfaces/dynamicThumbnail.interfaces';
import { DynamicThumbnailBG } from '../elements/DynamicThumbnailBG';
import { IProduct } from '../../../../../interfaces/product/IProduct';
import { useSrcFromMedia } from '../hooks/useSrcFromMedia';

interface ITemplateBC2P01 {
    thumbnail: IDynamicThumbnail;
    product: IProduct;
}

export const TemplateBC1P01: React.FC<ITemplateBC2P01> = (props) => {
    const { thumbnail, product } = props;

    const { imagePath } = useSrcFromMedia();

    return (
        <>
            <DynamicThumbnailBG thumbnail={thumbnail} />

            {/* Front Business card */}
            <img
                className="absolute border border-neutral-300 shadow-2xl transition-all"
                style={{
                    width: '80%',
                    height: '55%',
                    transform: 'perspective(600px) rotateY(15deg) rotateX(5deg) skewY(-2deg)',
                    transformOrigin: 'center',
                    top: '18%',
                    left: '12%',
                    zIndex: 2,
                }}
                src={imagePath(product.thumbnails[0])}
                alt="front-thumbnail"
            />
        </>
    );
};
