import { ICategory } from '../../../../../interfaces/category/ICategory';
import { IRole } from '../../../../../interfaces/role/IRole';
import { RawDraftContentState } from 'draft-js';
import { atom, selector } from 'recoil';
import { nanoid } from 'nanoid';

export interface IAdminCategoriesState {
    selectedCategoriesIDs: string[];
    categories: ICategory[];
    categoriesFilter: string;

    category: ICategory;

    roles: IRole[];
    rolesFilter: string;

    showCategoryEdit: boolean;

    selectedRowsInCategoriesTable: { [key: number]: boolean };

    thumbnailFile: File | null;
    imageFile: File | null;

    showThumbnailFileModal: boolean;
    showImageFileModal: boolean;
    showDescriptionModal: boolean;

    categoryDescription: RawDraftContentState | null;
}

export const adminCategoriesStateRecoil = atom<IAdminCategoriesState>({
    key: 'admin-categories-state-state',
    default: {
        selectedCategoriesIDs: [],
        categories: [],
        categoriesFilter: '',
        category: {
            id: '',
            name: '',
            description: '',
            parentCategoryID: undefined,
            children: [],
            parent: undefined,
            translationID: '',
            orderNo: undefined,
            accessRoleIDs: [],
            archived: undefined,
            code: undefined,
            accessLevel: undefined,
            productIDs: [],
            products: [],
            label: '',
            image: null,
            tenants: [],
        } as ICategory,
        roles: [],
        rolesFilter: '',
        showCategoryEdit: false,
        selectedRowsInCategoriesTable: {} as { [key: number]: boolean },
        thumbnailFile: null,
        imageFile: null,
        showThumbnailFileModal: false,
        showImageFileModal: false,
        showDescriptionModal: false,
        categoryDescription: null,
    },
});

// Categories Selection ######################################
export const getCategoriesFilter = selector<string>({
    key: `categories-filter/${nanoid()}`,
    get: ({ get }): string => {
        return get(adminCategoriesStateRecoil).categoriesFilter ?? '';
    },
});

// END of Categories Selection
